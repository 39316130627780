import React, { useState, useRef } from 'react'
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/vegcli-logo.jpeg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [userdata, setUserdata]   = useState({});
  const [resp, setResp]           = useState(false);
  const [respmsg, setRespmsg]     = useState('');
  const [respalert, setRespalert] = useState('0');
  const [input, setInput]         = useState('')
  // const handleInputChange = (e:any) => setCategname(e.target.value)
  const handleInputChange = (event:any) => {
      const { name, value } = event.target; 
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };
  const isError = input === ''
  const [state, setState] = useState({
        user: '',
        pass: '',
    });
  const handleSubmit = (event:any) => {
        event.preventDefault();
        // console.log(state);
        fetch(process.env.REACT_APP_API_BASEURL+'api/patients/login',{
        method: 'POST',
        headers: {
            Accept: 'application/json',
                    'Content-Type': 'application/json',
        },
        body: JSON.stringify(state)
        })
        .then((res) => res.json())
        .then(res => {
          // console.log(res.data);
          if(res.status=='success'){
            setRespmsg('Login Success!');
            setRespalert('1');
            localStorage.setItem('user', JSON.stringify(res.data[0].user));
            localStorage.setItem('usermail', JSON.stringify(res.data[0].email));
            localStorage.setItem('fname', JSON.stringify(res.data[0].fname));
            localStorage.setItem('lname', JSON.stringify(res.data[0].lname));
            // console.log(localStorage.getItem('usermail'));
            // setDashboard(true);
            window.location.replace('#/admin/medform/add');// admin/default
            localStorage.setItem('orgid', '');
          }else{
            setResp(true);
            setRespmsg('Invalid username or password!!!')
            setTimeout(() => setResp(false), 4000);
          }
        })
        .catch(error =>{
            console.log(error)
        })
    }; 
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='70%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Heading color={textColor} fontSize='36px' mb='10px'>
          Kovai Natural & Herbal Center
        </Heading>
        {/*<Heading color={textColorSecondary} fontSize='26px' mb='10px' textAlign={'center'}>
          (Food Consultancy Centre)
        </Heading>*/}
        <Box me='auto' ml='50px'>
          <Heading color={textColor} fontSize='26px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your username and password to sign in!
          </Text>
        </Box>
        <form onSubmit={handleSubmit}>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {/*<Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>*/}
          <FormControl ml='50px'>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Username<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='bala'
              mb='24px'
              fontWeight='500'
              size='lg'
              name='user'
              id='user'
              onChange={handleInputChange}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                name='pass'
                onChange={handleInputChange}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px' type='submit'>
              Sign In
            </Button>
            { resp &&
              <Alert status={respalert=='0' ? 'error':'success'}>
                <AlertIcon />
                {respmsg}
              </Alert>
            }
          </FormControl>
          {/*<Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>*/}
        </Flex>
        </form>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
