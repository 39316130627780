import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdBarChart, MdPerson, MdOutlineGroups, MdHome, MdOutlinePayments, MdCardMembership, MdOutlineShoppingCart, MdCardGiftcard, MdSettings, MdOutlineCalendarToday, MdOutlineBakeryDining, MdOutlineAttractions, MdGroupAdd, MdOutlineCancelPresentation } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Medform from 'views/admin/settings/Medicalform';
import PatDet from 'views/admin/settings/Patientdetails';
import Profile from 'views/admin/profile';
import Settings from 'views/admin/settings';
import DataTables from 'views/admin/dataTables';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
	// {
	// 	name: 'Medical Form',
	// 	layout: '/admin',
	// 	path: '/medform',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	component: Settings
	// },
	// {
	// 	name: 'Overview',
	// 	layout: '/admin',
	// 	path: '/default',
	// 	icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
	// 	component: MainDashboard
	// },	
	{
		name: 'Add Patient',
		layout: '/admin',
		path: '/medform/add',
		icon: <Icon as={MdOutlineCalendarToday} ml='10px' width='15px' height='15px' color='inherit' />,
		component: Medform,
		secondary: true,
	},
	{
		name: 'Edit Patient',
		layout: '/admin',
		path: '/medform/edit',
		icon: <Icon as={MdOutlineCalendarToday} ml='10px' width='15px' height='15px' color='inherit' />,
		component: Medform
	},	
	{
		name: 'Manage Patient Details',
		layout: '/admin',
		path: '/medform/list',
		icon: <Icon as={MdOutlineAttractions} ml='10px' width='15px' height='15px' color='inherit' />,
		component: PatDet,
		secondary: true,
	},
	{
		name: 'signIn',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdCardGiftcard} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	}

];

export default routes;
