import React, { useEffect, useState, useRef } from 'react';
import Swal from "sweetalert2";
import { useLocation} from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from './PDF';
import { Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, SimpleGrid, Box, 
useColorModeValue, Flex, Icon, Text, Switch, Tooltip, Image, useDisclosure, Stack, Input, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText, InputGroup, InputLeftElement, InputRightElement, Alert, AlertIcon 
} from '@chakra-ui/react';
import { MdEdit, MdVisibility, MdCancel, MdDelete, MdCheckCircle, MdOutlineError, MdFileDownload } from 'react-icons/md';
// import Popup from './components/Popup';
// import EditServices from './components/EditServices';

export default function Patientdetails(){
  if(!localStorage.getItem('user'))
    window.location.replace('#/auth/sign-in');
  // const location  = useLocation();
  // const textColor = useColorModeValue('secondaryGray.900', 'white'); 
  const [ patdet, setPatdet ] = useState([]);
  const [ id, setId ]       = useState('');
  const [ name, setName ]   = useState('');
  const [ regno, setRegno ] = useState('');
  // const [status, setStatus] = useState(2);
  const downloadLink        = useRef(null);

  const url       = process.env.REACT_APP_API_BASEURL+'api/patients';
  // const urlParams = new URLSearchParams(location.search);
  // const filter    = urlParams.get('filter') ? urlParams.get('filter') : '';
  useEffect(() => {   
      fetchdata();
  }, []);
  const fetchdata = async () => {
    const response = await fetch(url, 
      {
        method:'GET', 
        headers: {
          Accept: 'application/json',
                  'Content-Type': 'application/json',
        },
        // body: JSON.stringify({
        //     orgid: !location.pathname.includes('/ov/') ? localStorage.getItem('orgid') : '',
        //     filter:filter
        // })
      });
    const det = await response.json();
    if(det.data.length)
      setPatdet( det.data )
  }
   // console.log(patdet);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [thumb, setThumb] = useState(''); 
  const callPopup = (val:any) => {  
    // val.isOpen = 'true';
    // val.onClose='false';
    setThumb(val); 
   };   

  const [mtitle, setMtitle] = useState('');  
  const [images, setImages] = useState(''); 
  const [servData, setServData] = useState({name:'', image:'', view:'', edit:'', categname:'', price:'', discount:'', duration:'', descr:'', status:''});
  const imgPopup = (val:any) => {
    val.view  = val.edit = 'no';
    val.image = 'yes';
    setServData(val); 
    setMtitle('Patient Picture: '+val.name);
    setImages(val.servpic[0].img);
   }; 
  const viewPopup = (val:any) => {
    val.image  = val.edit = 'no';
    val.view   = 'yes';
    setServData(val); 
    setMtitle('View Patient : '+val.name);
   };
  const editPopup = (val:any) => {
    val.image  = val.view = 'no';
    val.edit   = 'yes';
    setServData(val); 
    setMtitle('Edit Patient : '+val.name);
   };  
   const download = (item:any) => {
    setId(item._id);
    setName(item.name);
    setRegno(item.regno);
    downloadLink.current.click();
   };   


  const [state, setState] = useState({
    status : 2    
  });
  const deletePatient = (event:any) => {
      // event.preventDefault();
      // console.log(state);//return;
      Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the patient data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var baseurl = process.env.REACT_APP_API_BASEURL+'api/patients/';
        var apiurl  = baseurl+event._id;
        var mtd     = 'PUT';
        fetch(apiurl,{
            method: mtd,
            headers: {
                Accept: 'application/json',
                        'Content-Type': 'application/json',
            },
            body: JSON.stringify(state)
          }).then(response => {
              // console.log(response)
              if(response.status==200){
                // event.target.reset();
                  // showToast(addToast);
                fetchdata();
                Swal.fire({
                  title: "Deleted!",
                  text: "Patient details has been deleted.",
                  icon: "success"
                });
                // window.location.replace('#/admin/medform/list');
              }
          })
          .catch(error =>{
              console.log(error)
          }) 
      }
    });
    };
  return(
    <Box className='boxpt' style={{ background:'#fff', fontSize:12}}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <TableContainer>
          <Table size='sm' width='100%'>
            <Thead>
              <Tr height='40px'>
                <Th width='3%'>#</Th>
                <Th width='10%'>Name</Th>
                <Th width='5%'>Reg. No.</Th>
                <Th width='7%'>Mobile</Th>
                <Th width='7%'>Email</Th>
                <Th width='15%'>Address</Th>
                <Th width='5%'>Age</Th>
                {/*<Th width='5%'>Status</Th> */}
                <Th width='10%'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patdet.map((item, index) => (
              <Tr key={index} style={{padding:5}}>
                <Th width='3%'>{index+1}</Th>
                <Th width='10%'>{item.name}</Th>
                <Th width='5%'>{item.regno}</Th>
                <Th width='7%'>{item.mobile}</Th>
                <Th width='7%'>{item.email}</Th>
                <Th width='15%'>{item.addr}
                  {/*<Tooltip hasArrow label={item.addr} bg='gray.300' color='black'>
                    {item.addr.slice(0,5)+'...'}
                  </Tooltip>*/}
                </Th>
                <Th width='5%'>{item.age}</Th>
                {/*<Th width='5%'>
                  <Flex align='center'>
                  <Icon
                    w='24px'
                    h='24px'
                    me='5px'
                    color={
                      item.status == 1 ? ('green.500') : item.status == 0 ? ('gray.500') : item.status == 2 ? ('orange.500'
                      ) : null
                    }
                    as={
                      item.status == 1 ? (MdCheckCircle) : item.status == 0 ? (MdCancel) : item.status == 2 ? (MdOutlineError
                      ) : null
                    }
                  />
                  <Text color={textColor}>
                    { (item.status==1) ? 'Active' : 'Disabled' }
                  </Text>
                </Flex> 
                </Th> */}
                <Th width='10%'>
                  {/*<span onClick={onOpen} className='point'><Icon as={MdVisibility} w='20px' h='20px' mr={2} onClick={() => viewPopup(item)}/></span> */}
                  <span className='point'><a href={'#/admin/medform/edit/'+item._id}><Icon as={MdEdit} w='20px' h='20px' mr={2} /></a></span>
                  <span className='point' onClick={() => deletePatient(item)}><Icon as={MdDelete} w='20px' h='20px' mr={2} /></span>
                  {/*<span className='point'><Icon as={MdEdit} w='20px' h='20px' mr={2} onClick={() => download(item)} /></span>*/}
                  <PDFDownloadLink document={<PDF id={item._id}/>} fileName={item.name+'_'+item.regno+".pdf"}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Icon as={MdFileDownload } w='20px' h='20px' mr={2} />)}
                  </PDFDownloadLink >
                </Th>
              </Tr>
              ))}
              { !patdet.length &&
              <Tr><Td colSpan={8}>No data found!</Td></Tr>
              }
            </Tbody>
          </Table>
        </TableContainer>
        {/*<PDFDownloadLink document={<PDF id={id}/>} fileName={name+'_'+regno+".pdf"} >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Icon as={MdFileDownload } w='20px' h='20px' mr={2} ref={downloadLink} id="dl"/>)}
        </PDFDownloadLink >*/}
      </SimpleGrid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{(servData.name) ? mtitle :''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            { servData.image=='yes' &&
            <Image src={images} alt={servData.name} />
            }
            { servData.view=='yes' &&
            <TableContainer>
              <Table size='sm'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{servData.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Category</Td>
                    <Td>{servData.categname}</Td>
                  </Tr>
                  <Tr>
                    <Td>Price</Td>
                    <Td>{servData.price}</Td>
                  </Tr>
                  <Tr>
                    <Td>Discount</Td>
                    <Td>{servData.discount}</Td>
                  </Tr>
                  <Tr>
                    <Td>Duration</Td>
                    <Td>{servData.duration}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td style={{whiteSpace: 'pre-wrap'}}>{servData.descr}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{servData.status ? 'Active':'Inactive'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            }
          </ModalBody>

          <ModalFooter>
            {/*<Button colorScheme="blue" mr={3} onClick={onClose}> Close </Button>*/}
            {/*<Button variant="ghost">Secondary Action</Button>*/}
          </ModalFooter>
        </ModalContent>
      </Modal>      
    </Box>  
  )
};