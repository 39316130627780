export const liquorHabitInputs = [
	{
		label: 'ஆல்கஹால்',
		name : 'lh_alcohol',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'புகையிலை',
		name : 'lh_tobacco',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'புகைப்பிடித்தல்',
		name : 'lh_smoking',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'போதை வஸ்துக்கள் ',
		name : 'lh_narcotics',
		ans  : 'இல்லை',
		monyr: 'input'
	}
];

export const diseaseInputs = [
	{
		label: 'உயர் ரத்த அழுத்தம் உள்ளவரா ?',
		name : 'di_highbp',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'சர்க்கரை நோய் உள்ளவரா ?',
		name : 'di_highsugar',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'இருதயம் சார்ந்த குறைபாடு உள்ளவரா ?',
		name : 'di_heartdisease',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அதிக கொழுப்பு உள்ளவரா ?',
		name : 'di_highcholesterol',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'உடல் பருமன் உள்ளவரா ?',
		name : 'di_highweight',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மற்றவை',
		name : 'di_others',
		ans  : '',
		monyr: ''
	}
];

export const painInputs = [
	{
		label: 'மூட்டு வலி',
		name : 'pi_jointpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கழுத்து வலி',
		name : 'pi_neckpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கை வலி',
		name : 'pi_handpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தொடை நரம்பு வலி ',
		name : 'pi_femoralnervepain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கணுக்கால் வலி',
		name : 'pi_anklepain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தோள்பட்டை வலி ',
		name : 'pi_shoulderpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அடி வயிறு வலி',
		name : 'pi_abdominalpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'முதுகு வலி',
		name : 'pi_backpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அடி முதுகு வலி',
		name : 'pi_lowbackpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'குதிகால் வலி',
		name : 'pi_heelpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மூட்டு வீக்கம்',
		name : 'pi_jointswelling',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பாத வலி',
		name : 'pi_footpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பாத மதமதப்பு',
		name : 'pi_footreligion',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பாத எரிச்சல்',
		name : 'pi_footirritation',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கெண்டைக்கால் வலி',
		name : 'pi_calfpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'விரல் மூட்டு வலி',
		name : 'pi_fingerjointpain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'இடுப்பு வலி',
		name : 'pi_hippain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அல்லையில் வலி',
		name : 'pi_paininarm',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மற்றவை',
		name : 'pi_others',
		ans  : '',
		monyr: ''
	}
];

export const illnessInputs = [
	{
		label: 'மனஅழுத்தம்',
		name : 'ii_depression',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தலை வலி',
		name : 'ii_headache',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['பசியால்', 'தலைக்கு குளிப்பதால்', 'எந்தவித காரணமின்றி', 'தசைவலியால்', 'கொழுப்பு உணவு செரிக்காமல்', 'மலச்சிக்கலால்', 'உடல் சூட்டினால்', 'சைனஸ் தொல்லையால்', 'வாந்தியுடன் கூடிய தலைவலி', 'அதிகமான வெயிலினால்', 'கழுத்து வழியால்']
	},
	{
		label: 'நரம்பு சார்ந்த குறைபாடு',
		name : 'ii_nerveissues',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['ஞாபக மறதி', 'கைநடுக்கம்', 'வலிப்பு', 'தூக்கமின்மை', 'மந்த புத்தி', 'உடலை தூக்கி தூக்கிப் போடுதல்', 'அடிக்கடி கொட்டாவி', 'நரம்பு துடித்தல் ( கண் நரம்பு )', 'உடல் வலி', 'அடிக்கடி தலை சுற்றல்', 'கை கால்கள் மரத்து போதல்', 'உடல் சோர்வு']
	},
	{
		label: 'கண் குறைபாடு',
		name : 'ii_eyeproblem',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['தூரப் பார்வை', 'ஒளிக்கூச்சம்', 'வீக்கம் / கட்டிகள்', 'நிறக் குருடு / புரை', 'மாலைக்கண்', 'கிட்டப்பார்வை', 'பகுதிப் பார்வை', ' நீர் வழிதல்', 'பார்வை இழப்பு', 'மங்கலான பார்வை / வறட்சி', 'சிவந்த கண்கள்', 'கருவளையம்']
	},
	{
		label: 'மூக்கு சார்ந்த குறைபாடு',
		name : 'ii_noseissues',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['சளி / துர் நாற்றம்', 'பச்சை நிற சளி', 'வெண்மை நிற சளி', 'மஞ்சள் நிற சளி', 'எழும்பு வளர்ச்சி', 'நுகரும் தன்மை இழப்பு', 'மூக்கடைப்பு', 'மூக்கு அரிப்பு', 'தொடர் தும்மல்', 'சதை வளர்ச்சி', 'உலர்ந்த நிலை', 'இரத்தம் வடிதல்']
	},
	{
		label: 'காது சார்ந்த குறைபாடு',
		name : 'ii_earissues',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['தாமதமாக புரிதல்', 'காதுகளில் அழுத்தம்', 'நிரந்தர அடைப்பு', 'சப்தம் / இரைச்சல்', 'குளிர்ந்த காற்று / நீரால் உண்டாகும் வலி', 'அழுக்கு சேர்தல் / சதை வளர்ச்சி', 'அரிப்பு / எரிச்சல்', 'வீக்கம் / பருக்கள்', 'மந்தமான செவித்திறன்', 'செவித்திறன் இழப்பு / வலி', 'தற்காலிக அடைப்பு', 'பலவீனமாக இருத்தல்']
	},
	{
		label: 'பல் சொத்தை ',
		name : 'ii_dentalissues',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தொண்டை ',
		name : 'ii_throatissues',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'நுரையீரல்',
		name : 'ii_lungs',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['பசியினால்', 'ஈரப்பதத்தால்', 'குளிரால் ', 'படுத்தால்', 'காற்று இல்லாததால்', 'அதிகப்படியான சளியால்', 'ஆஸ்துமா / வீசிங் குறைபாட்டினால்', 'இரத்தம் சளியுடன் கூடிய இருமல்', 'நெஞ்சுவலியுடன் கூடிய இருமல்', 'அமர்ந்தால்', 'வறட்டு இருமல்', 'குறட்டை ', 'தூசியால்', 'வாசனையால்']
	},
	{
		label: 'ஆஸ்துமா',
		name : 'ii_asthma',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'வீசிங்',
		name : 'ii_wheezing',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'காசநோய் ',
		name : 'ii_tuberculosis',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'குறட்டை',
		name : 'ii_snoring',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'வயிறு',
		name : 'ii_stomach',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['பசிதாங்க இயலாமை', 'அஜீரணக் குறைபாடு', 'வயிறு உப்புசம்', 'வயிற்றுப் பொருமல்', 'வாய்வுப் பிடிப்பு', 'பசியின்மை', 'சாப்பிட்டவுடன் மயக்க நிலை', 'சாப்பாட்டினால் ஒவ்வாமை', 'சாப்பிட்டவுடன் நெஞ்செரிப்பு', 'புளிப்பு ஏப்பம்', 'விக்கல்', 'தொடர் ஏப்பம்', 'ஏதுக்களித்தல்']
	},
	{
		label: 'அல்சர்',
		name : 'ii_ulcer',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மலக்குடல்',
		name : 'ii_rectum',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['எரிச்சலுடன்', 'வலியுடன்', 'இரத்தம் வெளியேறுதல்', 'ஆசனவாய் வெடிப்பு', 'மூலம்', 'பௌத்திரம்', 'மலக்குடல் வாய்வு', 'துர்நாற்றம் மலம்', 'மலச்சிக்கல்', 'சீழுடன் மலம்', 'இரத்த மலம்', 'சளி மலம்', 'வயிற்றுப போக்கு', 'அடிக்கடி மலம் கழித்தல்', 'கட்டுக்கடங்காத மலம்', 'IBS']
	},
	{
		label: 'மூலம்',
		name : 'ii_piles',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பௌத்திரம் ',
		name : 'ii_fistula',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'சிறுநீர்',
		name : 'ii_urine',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['மஞ்சள் நிறம்', 'எரிச்சல்', 'வலி', 'இரத்தம் கலந்து', 'காரமான நெடி', 'தும்மினால்', 'இருமினால்', 'நுரையுடன் கூடிய', 'விட்டு விட்டு', 'சீழுடன்', 'இரத்தம்', 'சளி', 'அழுக்கான நிறம்', 'அடிக்கடி', 'கட்டுப்படுத்த முடியாமல்']
	},
	{
		label: 'சிறுநீரகக் கல்',
		name : 'ii_kidneystone',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பித்தப்பை கல்',
		name : 'ii_gallbladderstone',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தோல் ',
		name : 'ii_skin',
		ans  : 'இல்லை',
		monyr: 'input',
		reasons: ['தோல் எரிச்சல்', 'நமச்சல்', 'மரு', 'மங்கு', 'கொப்பளங்கள்', 'வெடிப்பு', 'தடிப்பு', 'வெண்மை நிறம்', 'அரிப்பு', 'சீழுடன் கூடிய கட்டி', 'சீழுடன் கூடிய பருக்கள்', 'உள்ளங்கை வியர்வை', 'உள்ளங்கால் வேர்த்தல்', 'தோல் சுருக்கம்', 'தோல் வறட்சி', 'சிவந்த நிறம்', 'வெண் திட்டுக்கள்']
	},
	{
		label: 'தேமல்',
		name : 'ii_themal',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'எக்ஸீமா',
		name : 'ii_eczema',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'படர்தாமரை',
		name : 'ii_foliage',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'சொரியாசிஸ்',
		name : 'ii_psoriasis',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'பொடுகு ',
		name : 'ii_dandruff',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'வெண்திட்டு',
		name : 'ii_veṇthiṭṭu',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'நரம்பு சிலந்தி',
		name : 'ii_nervespider',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கால் ஆணி',
		name : 'ii_toenail',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'சைனஸ்',
		name : 'ii_sinus',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கல்லீரல் கொழுப்பு ',
		name : 'ii_liverfat',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கல்லீரல் வீக்கம்',
		name : 'ii_liverinflammation',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'குடல் இறக்கம்',
		name : 'ii_hernia',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'புற்று நோய் ',
		name : 'ii_cancer',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'முடக்கு வாதம்',
		name : 'ii_rheumatoid_arthritis',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'நடுக்கு வாதம்',
		name : 'ii_mediation',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மங்கு',
		name : 'ii_faded',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'ஆறாத புண்',
		name : 'ii_sore',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தூக்கமின்மை',
		name : 'ii_Insomnia',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'ஒட்டு குடல்',
		name : 'ii_colic',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'ஒவ்வாமை',
		name : 'ii_allergy',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'யூரிக் அமிலம்',
		name : 'ii_uricAcid',
		ans  : 'இல்லை',
		monyr: 'input'
	}
];

export const ladiesIllnessInputs = [
	{
		label: 'தைராய்டு',
		name : 'li_thyroid',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'PCOD',
		name : 'li_pcod',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'PCOS',
		name : 'li_pcos',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கருப்பைக் கட்டி',
		name : 'li_uterine_tumor',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'சினைப்பைக் கட்டி',
		name : 'li_tie_the_vagina',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அதிக இரத்த போக்கு ',
		name : 'li_excessive_blood_flow',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'நீடித்த போக்கு ',
		name : 'li_a_lasting_trend',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கட்டு படாத போக்கு ',
		name : 'li_an_unbridled_tendency',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'உடனடியாக நின்று விடுதல்',
		name : 'li_stop_immediately',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'போக்கு தவறுதல் ',
		name : 'li_failure_to_trend',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'தாமதமாக உண்டாகுதல்',
		name : 'li_late_onset',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'முன்பாகவே உண்டாகுதல்',
		name : 'li_pre_creation',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கட்டி கட்டியாக வெளியேறுதல்',
		name : 'li_a_lumpy_discharge',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'வெள்ளை படுதல்',
		name : 'li_whitewash',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'அரிப்பு எரிச்சல்',
		name : 'li_itchy_irritation',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'வலியுடன் கூடிய மாதவிடாய்',
		name : 'li_menstruation_with_pain',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மார்பகத்தில் கட்டி',
		name : 'li_breast_lump',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கர்ப்பப்பை கிழிறக்கம்',
		name : 'li_uterine_rupture',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'கர்ப்பப்பை நீக்கம்',
		name : 'li_hysterectomy',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'முடி கொட்டுதல் ',
		name : 'li_hair_loss',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'மார்பக புற்று நோய்',
		name : 'li_breast_cancer',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'குழந்தையின்மை',
		name : 'li_infertility',
		ans  : 'இல்லை',
		monyr: 'input'
	},
	{
		label: 'ஹீமோகுளோபின்..',
		name : 'li_hemoglobin',
		ans  : 'இல்லை',
		monyr: 'input'
	}
];	

export const ongoingTreatmentsInputs = [
	{
		label: 'ஆங்கில மருத்துவம்',
		name : 'ogt_englishmedicine',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் சிகிச்சை விவரம்'
	},
	{
		label: 'ஆயுர்வேதம்',
		name : 'ogt_ayurveda',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் சிகிச்சை விவரம்'
	},
	{
		label: 'சித்த மருத்துவம்',
		name : 'ogt_siddhamedicine',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் சிகிச்சை விவரம்'
	},
	{
		label: 'ஹோமியோபதி',
		name : 'ogt_homeopathymedicine',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் சிகிச்சை விவரம்'
	},
	{
		label: 'அக்குப் பஞ்சர்',
		name : 'ogt_akupanjar',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் சிகிச்சை விவரம்'
	},
	{
		label: 'பரம்பரை நோய் உள்ளதா?',
		name : 'ogt_hereditary_disease',
		ans  : 'இல்லை',
		pc   : 'ஆம் எனில் விவரம்'
	}
];

export const regTypes = [
	{
		label: 'சென்னை',
		name : 'CHE'
	},
	{
		label: 'கோவை',
		name : 'CBE'
	},
	{
		label: 'இணைய வழி',
		name : 'ONI'
	}
];