// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
//import { HorizonLogo } from 'components/icons/Icons';
import Logo from 'assets/img/vegcli-logo.jpeg';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	// let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
		<img width="175px" src={Logo} alt="Veg Clinic"/>
			{/*<HorizonLogo h='26px' w='175px' my='32px' color={logoColor} />*/}
			<HSeparator mb='10px' />
		</Flex>
	);
}

export default SidebarBrand;
